import React from 'react';
import { Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GridDisplay = ({ productsData }) => (
    <ul className="grid-display">
        {productsData.products.map(product => (
            <li key={product.id} className='grid-item'>
                <Link className='product__img-wrapper' to={`/product/${product.id}/edit`}>
                    <Image src={product.image} alt={product.name} fluid />
                    <div className="grid-item__info">
                        <b className='product__title'>{product.name}</b>
                        {/* <div className='product__caption'>{CATEGORIES[product.category]}</div> */}

                        <div className='product__price-wrapper'>
                            <div className='product__price'>
                                ¥{product.price}
                            </div>
                        </div>
                    </div>
                </Link>
            </li>
        ))}
    </ul>
);

export default GridDisplay;
