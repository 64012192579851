import React, { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import useQueryParams from '../hooks/useQueryParams';
import { PAGINATION_MAX_VISIBLE_PAGES, PAGINATION_PAGE_BUFFER } from '../constants/constants';

const Paginate = ({ currentPage, totalPages }) => {
    const [query, updateQueryParams] = useQueryParams();

    const currentPageNumber = parseInt(currentPage, 10) || 1;

    const generatePaginationItems = () => {
        const pages = [];
        const maxVisiblePages = PAGINATION_MAX_VISIBLE_PAGES;
        const buffer = PAGINATION_PAGE_BUFFER;

        if (totalPages <= maxVisiblePages + buffer * 2) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1);

            let start = Math.max(2, currentPageNumber - buffer);
            let end = Math.min(totalPages - 1, currentPageNumber + buffer);

            if (start > 2) {
                pages.push('...');
            }

            for (let i = start; i <= end; i++) {
                pages.push(i);
            }

            if (end < totalPages - 1) {
                pages.push('...');
            }

            pages.push(totalPages);
        }

        return pages;
    };

    const paginationItems = useMemo(() => generatePaginationItems(), [currentPageNumber, totalPages]);

    return (
        <Pagination className="justify-content-center">
            {currentPageNumber > 1 && (
                <Pagination.Prev
                    onClick={() => updateQueryParams({ page: currentPageNumber - 1 })}
                    onFocus={(e) => e.currentTarget.blur()}
                />
            )}

            {paginationItems.map((pageNumber, index) =>
                pageNumber === '...' ? (
                    <Pagination.Ellipsis key={`ellipsis-${index}`} disabled />
                ) : (
                    <Pagination.Item
                        key={`page-${pageNumber}`}
                        onClick={() => updateQueryParams({ page: pageNumber })}
                        active={pageNumber === currentPageNumber}
                    >
                        {pageNumber}
                    </Pagination.Item>
                )
            )}

            {currentPageNumber < totalPages && (
                <Pagination.Next
                    onClick={() => updateQueryParams({ page: currentPageNumber + 1 })}
                    onFocus={(e) => e.currentTarget.blur()}
                />
            )}
        </Pagination>
    );
};

export default Paginate;